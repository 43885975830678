import React from 'react';
import { graphql, Link } from 'gatsby';
import * as b from "../components/bootstrap.module.css"
import BlogContent from '../components/blog-content';
import TableOfContents from '../components/table-of-contents';
import RysioProductsAccount from '../../static/assets/Rysiek-account.svg'
import Breadcrumbs from "../components/breadcrumbs"
import Icon from "../components/icon"
import resolveUrl from '../utils/urls/resolve-url';
import Layout from '../components/layout';
import Posts from '../components/posts';
import Translate from '../components/translate';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props} description={props.data?.mysqlTerms?.description}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const Accounts = ({ 
  data: { subCategories, allMysqlTerms, mysqlTerms, allMysqlBlog },
  pageContext: { breadcrumbs },
  location: { pathname }, 
}) => {
  return (
    <Layout pathname={pathname}>
      <div>
        <section className={`intro-products2 ${b.py4} ${b.pyMd5} introProductsPage`}>
          <div className={[b.container, "breadcrumbs-mobile-products", b.pb3,"scroll-inner"].join(" ")}>
            <Breadcrumbs items={breadcrumbs} textWhite/>
          </div>
          <div className={`${b.container} ${b.pb2} ${b.positionRelative}`}>
            <div className={b.row}>
              <div className={b.colLg10}>
                <h1 className={`big-title ${b.pb3} ${b.w75} ${b.ptLg5} ${b.textWhite}`}>
                  {mysqlTerms.title}
                </h1>

                <div className={`intro-calculator ${b.p3} ${b.row} ${b.mx0}`}>
                  <div className={[b.colLg12,'blueToWhite',b.px1,'tp-name'].join(' ')}><Translate tKey="What account are you looking for?" /></div>
                    { allMysqlTerms.nodes.map(({ term_normalized, terms_path, term,cluster}, i) => (
                      <div className={[b.colLg3,b.mt2,,b.px1].join(' ')} key={i}>
                        <Link to={resolveUrl(terms_path, term_normalized)} >
                          <div className={['intro-calculator',b.py2,b.px3,b.dFlex,b.alignItemsCenter].join(' ')}>
                            <div>
                            {cluster === 'accounts_1' ? <Icon size={40} icon="Bank-account" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                            {cluster === 'accounts_2' ? <Icon size={40} icon="Piggy-bank" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                            {cluster === 'accounts_4' ? <Icon size={40} icon="Junior-account" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                            {cluster === 'accounts_5' ? <Icon size={40} icon="Branches" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                            {cluster === 'accounts_6' ? <Icon size={40} icon="Investment" className={[b.mr2,'icon-blog'].join(' ')} /> : ''}
                            </div>
                            <span className={['font-size-13','tp-name'].join(' ')}>{term}</span>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <RysioProductsAccount className='products-accounts-image'/>
        </section>
      </div>

      <div className={`${b.container} ${b.pt3} ${b.ptLg4}`}>
        <div className={["blog-content", b.row,'blueToGray', 'dark-li'].join(" ")}>
          <div className={[ b.colXl8, 'dark-theme-listing'].join(' ')}>
          {mysqlTerms.description && (
            <BlogContent content={mysqlTerms.description} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_alt && (
            <BlogContent content={mysqlTerms.description_alt} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          {mysqlTerms.description_additional && (
            <BlogContent content={mysqlTerms.description_additional} className={[b.pr1, b.mb4].join(" ")}/>
          )}
          </div>
          <TableOfContents selector=".post-content h2, .post-content h3, .post-content h4" />
        </div>
      </div>
        
      {subCategories.nodes.length > 0 && 
        <div className={[b.container, b.mt4,b.mb3,b.mtLg5].join(' ')}>
          <h2 className={["title", b.dBlock, b.mb4].join(' ')}><Translate tKey="Topics in category:" /> {mysqlTerms.term}</h2>
          
          <div className={['subcategory-row', b.row].join(' ')}>
            {subCategories.nodes.map(({path,term, term_normalized}, i) => (
              <div className={[b.colMd6, b.colLg4, b.colXl3, b.px1].join(' ')} key={i}>
                <Link to={resolveUrl(path, term_normalized)}>
                  <div className={['category-box',b.mt2,b.dFlex].join(' ')}>
                    <div className='category-bl'/>
                    <div className={b.p3}>{term}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      }

      {allMysqlBlog.nodes.length !== 0 && ( <>
        <section className={`${b.container} ${b.pt3} ${b.ptLg4} ${b.pb4} ${b.pbLg5} ${b.mbLg5}`}>
          <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}><Translate tKey="Related content" /> {mysqlTerms.term}</h2>
          <Posts items={allMysqlBlog.nodes} countItem={999} step={4}/>
        </section>
      </>)}
    </Layout>
  )
}

export default Accounts

export const query = graphql `
query AccountsQuery($mysqlId: Int!, $withoutUrls: [String]) {
  allMysqlTerms(filter: {cluster: {glob: "accounts_*"}}, sort: {fields: sort}, limit: 5) {
    nodes {
      term_normalized
      terms_path: path
      cluster
      term
    }
  }
  subCategories: allMysqlTerms(filter: {mysqlParent: {eq: $mysqlId}, is_article: {ne: 0}, template: {ne: "products"}, menu: {eq: null}}) {
    nodes {
      term
      term_normalized
      path
    }
  }
  allMysqlBlog(filter: {url: {nin: $withoutUrls}, term_id: {eq: $mysqlId}, is_category: {eq: 0}, description: {ne: null}, status: {eq: "publish"}}) {
    nodes {
      ...PostData
    }
  }
  allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "term" }}) {
    nodes {
      ...SeoData
    }
  }
  mysqlTerms(mysqlId: {eq: $mysqlId}) {
    description_additional
    description_alt
    description
    title
    term
  }
}
`